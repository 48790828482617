import styled from '@emotion/styled';
import { css } from '@emotion/core';
import React from 'react';
import { useVisible } from 'components/AppearSensor/useVisible';
import cx from 'classnames';

interface IProps {
  children?: React.ReactNode;
  animation?: string;
  delay: number;
  tag: keyof JSX.IntrinsicElements;
  className: string;
  duration: number;
  alt?: string;
  src?: string;
  visible: boolean;
}

interface IComponentProps {
  visible: boolean;
  animated: boolean;
  keyframes?: string;
  duration: number;
  as: string;
}

const Component = styled.div<IComponentProps>`
  opacity: ${(props) => (props.visible ? 1 : 0)};
  ${(props) =>
    props.keyframes &&
    css`
      &.animated {
        animation-name: ${props.keyframes};
        animation-duration: ${props.duration}ms;
        animation-fill-mode: both;
        animation-delay: 10ms;
      }
    `}
`;

export const Animation = ({
  animation,
  className,
  children,
  tag,
  delay,
  ...props
}: IProps) => {
  const visible = useVisible();
  const [animated, setAnimated] = React.useState(false);
  React.useEffect(() => {
    if (visible) {
      setTimeout(() => {
        setAnimated(true);
      }, delay);
    }
  }, [visible, delay]);
  return (
    <Component
      as={tag}
      keyframes={animation}
      animated={animated}
      className={cx(className, { animated })}
      {...props}
    >
      {children && children}
    </Component>
  );
};

Animation.defaultProps = {
  duration: 1000,
  tag: 'div',
  visible: false,
};
